<template>
  <g :fill="color" fill-rule="evenodd">
    <defs>
      <path
        id="a"
        d="M520.941 371.78a.86.86 0 0 1 .059.314v5.18c0 .477-.39.864-.87.864a.867.867 0 0 1-.87-.864v-3.185l-2.55 2.38a8.735 8.735 0 0 1-8.069 2.318 8.676 8.676 0 0 1-6.278-5.543.862.862 0 0 1 .53-1.102.871.871 0 0 1 1.11.526 6.94 6.94 0 0 0 5.022 4.434 7 7 0 0 0 6.475-1.874l2.435-2.272h-3.022a.867.867 0 0 1-.87-.864c0-.477.39-.864.87-.864h5.217a.87.87 0 0 1 .807.54l.004.011zm-19.882-4.56a.86.86 0 0 1-.059-.314v-5.18c0-.477.39-.864.87-.864s.87.387.87.864v3.185l2.55-2.38a8.735 8.735 0 0 1 8.069-2.318 8.676 8.676 0 0 1 6.278 5.543.862.862 0 0 1-.53 1.102.871.871 0 0 1-1.11-.526 6.94 6.94 0 0 0-5.022-4.434 7 7 0 0 0-6.475 1.874l-2.435 2.272h3.022c.48 0 .87.387.87.864s-.39.864-.87.864h-5.217a.87.87 0 0 1-.807-.54l-.004-.011z"
      />
    </defs>
    <use :fill="color" fill-rule="evenodd" transform="matrix(-1 0 0 1 521 -360)" xlink:href="#a" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
